import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContainer,
  Logo,
  QRCodeWrapper,
  QRCodeImage,
  TextWrapper,
  ScratchArea,
  CardNumber,
  StyledHR,
} from './PspGiftCard.styles';
import logoTopWhite from '../../../assets/cards/logo-top.png';
import logoTopBlack from '../../../assets/cards/logo-top-w.png';

function WhiteOrBlackGiftCard({ id, qrCode, cardNumber = '', pin = '', cardType = 'black' }) {
  const formattedCardNumber = cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ');

  return (
    <CardContainer id={id} theme={cardType}>
      <Logo theme={cardType}>
        <img src={cardType === 'black' ? logoTopBlack : logoTopWhite} alt="PSP" style={{ marginRight: '10px' }} />
        <span>PSP SERVICES</span>
      </Logo>
      <QRCodeWrapper theme={cardType}>
        <QRCodeImage theme={cardType} src={qrCode} alt="QR Code" />
      </QRCodeWrapper>
      <TextWrapper theme={cardType}>
        <p>PAYMENTS</p>
        <p>eCOMMERCE</p>
        <p>POINT OF SALE</p>
      </TextWrapper>
      <StyledHR theme={cardType} />
      <CardNumber theme={cardType}>{formattedCardNumber}</CardNumber>
      <ScratchArea theme={cardType}>{pin}</ScratchArea>
    </CardContainer>
  );
}

WhiteOrBlackGiftCard.propTypes = {
  qrCode: PropTypes.string,
  cardNumber: PropTypes.string,
  pin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardType: PropTypes.oneOf(['white', 'black']),
};

export default WhiteOrBlackGiftCard;
