/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import api from 'services/api';
import JSZip from 'jszip';
import * as htmlToImage from 'html-to-image';
import { createRoot } from 'react-dom/client';
import { BtnWrap } from './DownloadQr.styles';
import GiftCards from '../GiftCards';

function DownloadQr({ onClose = () => {} }) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, { refetch: 1 });
  const [state, setState] = useState({ dateRange: [null, null], qr_codes: [] });
  const [startDate, endDate] = state.dateRange;
  const [form] = useForm();
  const qrRef = useRef([]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        business_no: state?.business?.business_no,
        batch_id: state?.batch?.value,
        re_download: !!state?.re_download,
      };
      const res = await api.printQr(payload);
      if (res?.data?.downloaded) {
        setState(prev => ({ ...prev, showConfirmModal: true, re_download: true }));
        setLoading(false);
        Toast({ type: 'error', message: "Some of the Qr's have already been downloaded" });
        return;
      }
      const rootElement = document.getElementById('psp-cardsss');

      // Use createRoot to render your GiftCards component
      const root = createRoot(rootElement);
      root.render(<GiftCards records={res.data} />);
      qrRef.current = res.data;
      setState(prev => ({ ...prev, qr_codes: res.data }));

      // Use a longer timeout to ensure styles and images are fully loaded
      setTimeout(async () => {
        try {
          const base64Images = [];
          const front_card_node = document.getElementById('psp-front');

          // Make sure node exists
          if (!front_card_node) {
            Toast({ type: 'error', message: 'Front card element not found in the DOM' });
            setLoading(false);
            return;
          }

          // Ensure all images inside the front card are loaded
          const frontCardImages = [...front_card_node.querySelectorAll('img')];
          await Promise.all([
            ...frontCardImages.map(img => {
              if (img.complete) return Promise.resolve();
              return new Promise(resolve => {
                img.onload = resolve;
                img.onerror = resolve; // Continue even if image fails
              });
            }),
          ]);

          // Use consistent settings for card capture
          const dataUrlFront = await htmlToImage.toPng(front_card_node, {
            quality: 1,
            width: front_card_node.offsetWidth,
            height: front_card_node.offsetHeight,
            backgroundColor: '#ffffff',
            style: {
              transform: 'none',
            },
          });

          if (dataUrlFront.length < 1000) {
            Toast({ type: 'error', message: 'Failed to capture front card image properly' });
            setLoading(false);
            return;
          }

          base64Images.push({ name: `card_front`, value: dataUrlFront });

          // Process all cards in sequence
          for (const itm of res.data) {
            const node = document.getElementById(itm?._id);

            if (!node) {
              console.error(`Card element with ID ${itm?._id} not found`);
              continue;
            }

            const cardImages = [...node.querySelectorAll('img')];
            await Promise.all([
              ...cardImages.map(img => {
                if (img.complete) return Promise.resolve();
                return new Promise(resolve => {
                  img.onload = resolve;
                  img.onerror = resolve;
                });
              }),
            ]);

            const dataUrl = await htmlToImage.toPng(node, {
              quality: 1,
              width: node.offsetWidth,
              height: node.offsetHeight,
              backgroundColor: '#ffffff',
              style: {
                transform: 'none',
              },
            });

            if (dataUrl.length > 1000) {
              base64Images.push({ name: `${itm?.card_number}_back`, value: dataUrl });
            } else {
              console.error(`Failed to capture card ${itm?.card_number} properly`);
            }
          }

          if (base64Images.length === 0) {
            Toast({ type: 'error', message: 'Failed to capture any card images' });
            setLoading(false);
            return;
          }

          const zip = new JSZip();
          base64Images.forEach(itm => {
            const binaryData = atob(itm?.value.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < binaryData.length; i++) {
              uint8Array[i] = binaryData.charCodeAt(i);
            }

            // Add the image to the zip file with a unique name
            zip.file(`${itm?.name}.png`, uint8Array, { binary: true });
          });

          // Generate the zip file
          const content = await zip.generateAsync({ type: 'blob' });

          // Create a download link and trigger the download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = `qr_${new Date().getTime()}`;
          link.click();
          setLoading(false);
          onClose();
          Toast({
            type: 'success',
            message: res?.message,
          });
          refetch();
        } catch (error) {
          console.error('Error capturing card images:', error);
          Toast({ type: 'error', message: `Error capturing cards: ${error.message}` });
          setLoading(false);
        }
      }, 2500); // Increased timeout substantially to ensure proper rendering
    } catch (ex) {
      setLoading(false);

      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };
  const handlePortalUserSearch = async __ => {
    try {
      const response = await api.getPspBusinesses({
        page: 1,
        pageSize: 10,
        searchText: __,
      });
      const options = response?.data?.items?.map(_ => ({
        value: _?.title,
        label: _?.title,
        business_no: _?.bid,
        business_id: _?._id,
      }));

      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const handleBatchesSearch = async __ => {
    try {
      const response = await api.getQrBatches({
        page: 1,
        pageSize: 10,
        searchText: __,
      });

      return response?.data?.items ?? [];
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  function ReConfirmModal() {
    return (
      <>
        <Heading level={4}>
          Some of the QR&quot;s are already been downloaded. Are you sure you want to download them again?
        </Heading>
        <BtnWrap>
          <Button
            type="outline"
            htmlType="submit"
            onClick={() => {
              setState(prev => ({ ...prev, showConfirmModal: false, re_download: false }));
            }}>
            No
          </Button>
          <Button
            type="danger"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              setState(prev => ({ ...prev, re_download: true }));
              setLoading(true);
              handleSubmit();
            }}>
            Yes
          </Button>
        </BtnWrap>
      </>
    );
  }
  return !state?.showConfirmModal ? (
    <Form form={form} onSubmit={handleSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          label="Business"
          name="business"
          placeholder="Select Business"
          type="select"
          async
          defaultOptions
          rules={[{ required: false, message: 'Please select Business' }]}
          loadOptions={handlePortalUserSearch}
          filterOption={false}>
          <Select />
        </Form.Item>
        <Form.Item
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select date range"
          type="datepicker"
          label="Date Range"
          name="dateRange"
          noMargin
          selectsRange
          clear={startDate || endDate}
          startDate={startDate}
          endDate={endDate}
          onChange={({ target: { value } }) => {
            setState(prev => ({ ...prev, dateRange: value }));
            form.setFieldsValue({ dateRange: value });
          }}
          rules={[{ required: false, message: 'This field is required' }]}>
          <Field />
        </Form.Item>
        <Form.Item
          label="Select Batch"
          name="batch"
          placeholder="Select Batch"
          type="select"
          async
          defaultOptions
          rules={[{ required: false, message: 'Please select Batch' }]}
          loadOptions={handleBatchesSearch}
          filterOption={false}>
          <Select />
        </Form.Item>
      </Grid>
      <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
        Download
      </Button>
    </Form>
  ) : (
    <ReConfirmModal />
  );
}

export default DownloadQr;
