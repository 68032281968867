import styled from 'styled-components/macro';

export const CardContainer = styled.div`
  width: 4500px;
  height: 7870px;
  background: ${props => (props.theme === 'black' ? 'url(/bg-black.png) no-repeat center center;' : '#ffffff')};
  background-size: cover;
  color: ${props => (props.theme === 'black' ? '#fff' : '#000')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 254px;
  box-shadow: 0px 50px 127px rgba(0, 0, 0, 0.5);
  border-radius: 152px;
  position: relative;
`;

export const Logo = styled.div`
  position: absolute;
  top: 508px;
  display: flex;
  align-items: center;
  img {
    height: 700px;
    width: auto;
  }
  span {
    margin-left: 127px;
    color: ${props => (props.theme === 'black' ? '#fff' : '#000')};
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 400px;
    font-style: normal;
    font-weight: bold;
    line-height: 15px; /* 150% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

export const QRCodeWrapper = styled.div`
  margin-bottom: 419px;
  background: white;
  padding: 102px;
  border-radius: 127px;
  border: ${props => (props.theme === 'black' ? '13px solid #fff' : '13px solid #000')};
`;

export const QRCodeImage = styled.img`
  width: 2032px;
  height: 2032px;
`;

export const TextWrapper = styled.div`
  margin-top: 254px;
  text-align: center;
  font-size: 229px;
  font-weight: bold;
`;

export const ScratchArea = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 508px;
  width: 2565px;
  height: 660px;
  color: white;
  gap: 127px;
  background: ${props => (props.theme === 'black' ? '#313131' : '#27548C')};
  border: ${props => (props.theme === 'black' ? '13px solid #313131' : '13px solid #27548C')};
  border-radius: 102px;
  font-size: 25rem;
  text-align: center;
`;

export const CardNumber = styled.div`
  color: ${props => (props.theme === 'black' ? '#fff' : '#000')};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Plus Jakarta Sans';
  font-size: 305px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 6px;
  text-transform: uppercase;
`;

export const StyledHR = styled.hr`
  width: 3645px;
  height: 13px;
  opacity: 0.1;
  background: ${props => (props.theme === 'black' ? '#fff' : '#000')};
`;
