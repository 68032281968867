import GiftCards from 'components/organisms/GiftCards';
import { default_data } from 'helpers/common';

export default function App() {
  return (
    <>
      <GiftCards records={[{ ...default_data, premiumStatus: 'white' }]} />;
    </>
  );
}
